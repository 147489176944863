/*!
 * Theme Name: The Property Store Theme
 * Description: The Property Store Theme
 * Author: Lyles Sutherland Ltd.
 * Template: Lyles-Sutherland-Theme
 * Version: 0.0.1
 * Year: 2016/12
*/

@import "vendor/bootstrap/bootstrap";
@import "vendor/fontawesome/font-awesome";
@import "vendor/slick/slick";
@import "vendor/slick/slick-theme";
@import "vendor/jquery-cookiebar/jquery-cookiebar";

@import "utils/variables";
@import "utils/responsive";
@import "utils/mixins";
@import "utils/basic";
@import "utils/typography";

@import "components/pagination";
@import "components/assets";
@import "components/forms";
@import "components/buttons";
@import "components/accordions";

@import "teases/post-tease";
@import "teases/staff-tease";
@import "teases/testimonial-tease";

@import "wp-properties/properties-pagination";
@import "wp-properties/properties-searchers";
@import "wp-properties/properties";
@import "wp-properties/properties-overview";
@import "wp-properties/single-property";
@import "wp-properties/property-gallery";
@import "wp-properties/properties-assets";
@import "wp-properties/properties-accordions";
@import "wp-properties/properties-search";
@import "wp-properties/properties-supermap";

@import "layout/footer";
@import "layout/header";
@import "layout/masthead";
@import "layout/navigations";
@import "layout/sidebars";

@import "pages/front-page";
@import "pages/tenants";
@import "pages/general";
@import "pages/contact-us";
@import "pages/page-investment";
